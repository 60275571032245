<template>
  <div style="width: 100%; position: relative">
    <div class="common-gaine" style="padding-top: 100px">
      <div class="common-plate-title">
        <span></span>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
      <div
        class="order-address-select flex-cen"
        @click="selectAddress"
        v-if="JSON.stringify(address) == '{}'"
      >
        <i class="iconfont icon-dizhi"></i
        >{{ $trans('添加收货地址', '添加收货地址') }}
      </div>

      <div class="order-address-select" v-else>
        <AddressItem :data="address" class="address-box" />
      </div>
      <div class="order-info-container">
        <OrderItem
          :data="item"
          v-for="(item, index) in goodsDetailVoList"
          :key="index"
        />
        <div class="no-payment-bottom line-bottom line-top">
          <div class="no-payment-subtotal font-medium flex-bet-cen">
            <span>{{ $trans('小计', '小计') }}</span>
            <div class="object__price" style="color: #2a9dcc">
              <div style="font-style: normal">￥</div>
              <div class="object__int">{{ sumMoney | product_int }}</div>
              <div class="object__float">{{ sumMoney | product_float }}</div>
            </div>
          </div>
          <div class="no-payment-order font-regular flex-bet-cen">
            <span>{{ $trans('开具发票', '开具发票') }}</span>
            <div @click="openTicketHandle" class="no-payment-serial flex-cen">
              <span>{{ openTicket }}</span>
              <i class="iconfont icon-xiangyou1"></i>
            </div>
          </div>
          <div class="no-payment-order font-regular flex-bet-cen">
            <span>{{ $trans('优惠券', '优惠券') }}</span>
            <el-dropdown @command="discountHandleCommand" trigger="click">
              <span class="el-dropdown-link">
                {{ discountText }}<i class="iconfont icon-xiangyou1"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="item"
                  v-for="(item, index) in couponVoSelectList"
                  :tabindex="index"
                  :key="index"
                >
                  {{ item.showText }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="no-payment-order font-regular flex-bet-cen">
            <span>{{ $trans('配送服务', '配送服务') }}</span>
            <div class="no-payment-serial flex-cen" @click="showMail = true">
              <span>{{ extraCharge }}</span>
              <i class="iconfont icon-xiangyou1"></i>
            </div>
          </div>
          <div class="no-payment-order font-regular flex-bet-cen">
            <span>{{ $trans('支付方式', '支付方式') }}</span>
            <el-dropdown @command="payHandleCommand" trigger="click">
              <span class="el-dropdown-link">
                {{ $trans(payText, payText)
                }}<i class="iconfont icon-xiangyou1"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="item"
                  v-for="(item, index) in paySelectList"
                  :key="index"
                  >{{ $trans(item, item) }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="no-payment-bottom">
          <div class="no-payment-subtotal font-medium flex-bet-cen">
            <span>{{ $trans('实付', '实付') }}</span>
            <div class="object__price" style="color: #2a9dcc">
              <div style="font-style: normal">￥</div>
              <div class="object__int">{{ actualSumMoney | product_int }}</div>
              <div class="object__float">
                {{ actualSumMoney | product_float }}
              </div>
            </div>
          </div>
        </div>
        <div class="btn-fill" @click="submitHandle">
          {{ $trans('提交订单', '提交订单') }}
        </div>
      </div>
    </div>
    <div class="shop-detail-mask" v-show="showMail">
      <div class="mask-content">
        <div class="mask-content-header font-medium">
          {{ $trans('配送服务', '配送服务') }}
        </div>
        <div class="mask-category__wrap">
          <div
            class="mask-list-item"
            v-for="(item, index) in shangsfSkuTemplateItemList"
            :key="index"
          >
            <div
              v-for="(ite, ind) in item.shangsfSkuTemplateItemList"
              :key="ind"
              class="flex-bet-cen"
            >
              <span>{{ ite.stuffName }}</span>
              <span class="mask__price"
                >￥{{ ite.stuffValue + '*' + item.count }}</span
              >
            </div>
          </div>
        </div>
        <div class="confirm-button btn-fill" @click="showMail = false">
          {{ $trans('确定', '确定') }}
        </div>
        <div class="mask-delete" @click="showMail = false">×</div>
      </div>
    </div>
    <PaymentItem ref="payMethod" />
  </div>
</template>
<script>
import AddressItem from '@/components/address/Address.vue'
import OrderItem from '@/components/order/OrderItem.vue'
import PaymentItem from '@/components/payment/wxScan.vue'
import { addInvoice } from '@/util/js/api.js'
export default {
  data() {
    return {
      address: {}, // 地址
      showMail: false, // 是否显示
      payText: '请选择支付方式', // 支付方式
      payAllList: [
        // 支付列表
        '微信',
        '支付宝',
      ],
      paySelectList: [], // 渲染支付列表
      discountText: '暂未使用优惠券', // 打折选择文本
      discountObj: {}, // 打折选择
      discountAllList: [
        // 打折数据列表完整
        {
          text: '8.5折',
          index: 0,
          type: 0,
        },
        {
          text: '满500-100',
          index: 1,
          type: 1,
        },
      ],
      discountSelectList: [], // 渲染打折列表
      QRCode: '', // 微信二维码地址
      dialogFormVisible: false, // 二维码弹出层
      goodsDetailVoList: [], // 商品信息
      openTicket: '', // 是否开具发票
      openTicketData: {}, // 开具发票数据
      batchNo: '', // 订单信息
      // serveCost:2000,     // 配送费用
      shangsfSkuTemplateItemList: [], // 额外费用列表
      couponVoList: [], // 获取打折数据列表完整
    }
  },
  components: {
    AddressItem,
    OrderItem,
    PaymentItem,
  },
  methods: {
    init() {
      this.paySelectList = JSON.parse(JSON.stringify(this.payAllList))
      this.address = this.$route.query.address
        ? { ...JSON.parse(this.$route.query.address) }
        : {}
      this.goodsDetailVoList = this.$route.query.goodsDetailVoList
        ? [...JSON.parse(this.$route.query.goodsDetailVoList)]
        : {}
      this.openTicket = this.$route.query.a
        ? this.$route.query.a
        : this.$trans('本次未开具发票', '本次未开具发票')
      this.openTicketData = this.$route.query.ticket
        ? JSON.parse(this.$route.query.ticket)
        : {}
      this.shangsfSkuTemplateItemList = this.$route.query
        .shangsfSkuTemplateItemList
        ? JSON.parse(this.$route.query.shangsfSkuTemplateItemList)
        : []
      this.couponVoList = this.$route.query.couponVoList
        ? JSON.parse(this.$route.query.couponVoList)
        : []
    },
    // 点击支付操作
    submitHandle() {
      if (!this.$route.query.address || this.$route.query.address == 'null') {
        this.$message.warning(this.$trans('请选择收货地址', '请选择收货地址'))
        return
      }
      if (this.payText == '请选择支付方式') {
        this.$message.warning(this.$trans('请选择支付方式', '请选择支付方式'))
        return
      }
      const params = {
        addressId: JSON.parse(this.$route.query.address).id,
        couponVo: {
          goodsIdList: this.useDiscountShop,
          id: this.discountObj.id,
          value: this.discountObj.value,
          type: this.discountObj.type,
        },
        goodsVoList: [],
        orderToken: JSON.parse(this.$route.query.orderToken),
        payPrice: this.actualSumMoney,
        payType: this.payText == '支付宝' ? 2 : 1,
        userId: this.$store.state.userId,
        isCartGoods: this.$route.query.isCartGoods,
      }
      JSON.parse(this.$route.query.goodsDetailVoList).forEach((item) => {
        let param = {
          count: item.count,
          skuId: item.skuId,
        }
        params.goodsVoList.push(param)
      })
      if (JSON.stringify(this.openTicketData) == '{}') {
        this.$refs.payMethod.paymentHandle(params)
        return
      }
      addInvoice(this.openTicketData)
        .then((res) => {
          if (res.code != '200') {
            this.$message.error(
              this.$trans('发票添加失败,请稍后再试', '发票添加失败,请稍后再试')
            )
          } else {
            this.$refs.payMethod.paymentHandle({
              ...params,
              invoiceId: res.data,
            })
          }
        })
        .catch(() => {
          this.$message.error(
            this.$trans('发票添加失败,请稍后再试', '发票添加失败,请稍后再试')
          )
        })
    },
    // 开具发票
    openTicketHandle() {
      this.$router.history.push({
        path: '/order/openTicket',
        query: {
          ...this.$route.query,
        },
      })
    },
    // 支付方式下拉列表
    payHandleCommand(command) {
      this.payText = command
      this.paySelectList = JSON.parse(JSON.stringify(this.payAllList))
      let i = this.paySelectList.findIndex((item) => item == command)
      this.paySelectList.splice(i, 1)
    },
    // 优惠券下拉列表
    discountHandleCommand(command) {
      this.discountObj = command
      this.discountText = command.showText
      this.discountSelectList = JSON.parse(JSON.stringify(this.discountAllList))
      let i = this.discountSelectList.findIndex(
        (item) => item.text == command.text && item.index == command.index
      )
      this.discountSelectList.splice(i, 1)
    },
    // 选择地址
    selectAddress() {
      this.$router.history.push({
        path: '/address',
        query: {
          ...this.$route.query,
          path: this.$route.path,
        },
      })
    },
    sumMoney2() {
      return this.goodsDetailVoList.length > 0
        ? this.goodsDetailVoList.reduce(
            (a, b) =>
              a +
              Math.floor(b.count * b.price * this.discountObj.value * 100) /
                100,
            0
          )
        : 0
      // return 0
    },
  },

  beforeRouteLeave(to, from, next) {
    const orderInfo = {
      payText: this.payText,
      discountObj: this.discountObj,
    }
    // console.log('orderInfo', orderInfo)
    localStorage.setItem('orderInfo', JSON.stringify(orderInfo))
    next()
  },
  mounted() {
    this.init()
    const obj = JSON.parse(localStorage.getItem('orderInfo'))
    if (obj) {
      // console.log('obj', obj)
      if (obj && obj['payText']) {
        this.payText = obj['payText']
      }
      if (obj && obj['discountObj']) {
        this.discountObj = obj['discountObj']
        this.discountText = this.discountObj['showText']
      }
      localStorage.setItem('orderInfo', '{}')
    }
  },
  // destroyed() {
  //   console.log('destroyed')
  //   localStorage.setItem('orderInfo2', '{}')
  // },
  computed: {
    // 小计
    sumMoney() {
      return this.goodsDetailVoList.length > 0
        ? this.goodsDetailVoList.reduce((a, b) => a + b.count * b.price, 0)
        : 0
      // return 0
    },

    // 实际支付
    actualSumMoney() {
      if (JSON.stringify(this.discountObj) == '{}') {
        return Number(
          (Math.floor(this.sumMoney * 100) / 100 + this.extraCharge).toFixed(2)
        )
      } else if (this.discountObj.type == 11) {
        return Number((this.sumMoney2() + this.extraCharge).toFixed(2))
        // return Number((Math.floor((this.sumMoney * this.discountObj.value)*100)/100 + this.extraCharge).toFixed(2))
      } else if (this.discountObj.type == 22) {
        return Number(
          (
            Math.floor((this.sumMoney - this.discountObj.value) * 100) / 100 +
            this.extraCharge
          ).toFixed(2)
        )
      } else if (this.discountObj.type == 33) {
        return this.sumMoney2 > this.discountObj.conditionAmount
          ? Number((this.sumMoney2() + this.extraCharge).toFixed(2))
          : this.sumMoney2 + this.extraCharge
        // return this.sumMoney > this.discountObj.conditionAmount ? Number((Math.floor((this.sumMoney * this.discountObj.value)*100)/100 + this.extraCharge).toFixed(2)) : this.sumMoney + this.extraCharge
      } else if (this.discountObj.type == 44) {
        return this.sumMoney > this.discountObj.conditionAmount
          ? Number(
              (
                Math.floor((this.sumMoney - this.discountObj.value) * 100) /
                  100 +
                this.extraCharge
              ).toFixed(2)
            )
          : this.sumMoney + this.extraCharge
      } else if (this.discountObj.type == 55) {
        let a = JSON.parse(JSON.stringify(this.goodsDetailVoList))
        let b = a.filter((item) => {
          return this.discountObj.goodsIdList.includes(item.skuId)
        })
        let c
        if (b.length == 1) {
          c = b[0]
        } else {
          c = b.reduce((e, f) => (e.price > f.price ? e : f))
        }
        return (
          Math.floor(
            (this.sumMoney -
              c.price * (1 - this.discountObj.value) +
              this.extraCharge) *
              100
          ) / 100
        )
      } else if (this.discountObj.type == 66) {
        return (
          Math.floor(
            (this.sumMoney - this.discountObj.value + this.extraCharge) * 100
          ) / 100
        )
      } else if (this.discountObj.type == 77) {
        let a = JSON.parse(JSON.stringify(this.goodsDetailVoList))
        let b = a.filter((item) => {
          return (
            this.discountObj.goodsIdList.includes(item.skuId) &&
            item.price * parseInt(item.count) > this.discountObj.conditionAmount
          )
        })
        let c
        if (b.length == 1) {
          c = b[0]
        } else {
          c = b.reduce((e, f) => e.price > f.price)
        }
        return (
          Math.floor(
            (this.sumMoney -
              c.price * (1 - this.discountObj.value) +
              this.extraCharge) *
              100
          ) / 100
        )
      } else if (this.discountObj.type == 88) {
        return (
          Math.floor(
            (this.sumMoney - this.discountObj.value + this.extraCharge) * 100
          ) / 100
        )
      } else {
        return 0
      }
    },
    // 额外支付费用
    extraCharge() {
      if (JSON.stringify(this.shangsfSkuTemplateItemList) == '[]') {
        return 0
      } else {
        return this.shangsfSkuTemplateItemList.reduce((a, b) => {
          if (b.shangsfSkuTemplateItemList) {
            return (
              Math.floor(
                (a +
                  b.shangsfSkuTemplateItemList.reduce((c, d) => {
                    return c + d.stuffValue * parseInt(b.count)
                  }, 0)) *
                  100
              ) / 100
            )
          } else {
            return Math.floor(a * 100) / 100
          }
        }, 0)
      }
    },
    // 优惠券完整列表
    couponVoPayList() {
      let couponVoList = JSON.parse(JSON.stringify(this.couponVoList))
      let a = couponVoList.map((item) => {
        let params = { ...item }
        if (item.type == 11) {
          params.showText =
            `${this.$trans('全场', '全场')} ` +
            item.value.toString().split('.')[1] +
            ` ${this.$trans('折', '折')}`
        } else if (item.type == 22) {
          params.showText =
            `${this.$trans('全场减', '全场减')} ` +
            item.value +
            ` ${this.$trans('元', '元')}`
        } else if (item.type == 33) {
          params.showText =
            `${this.$trans('全场满', '全场满')} ` +
            item.conditionAmount +
            ` ${this.$trans('元打', '元打')} ` +
            item.value.toString().split('.')[1] +
            ` ${this.$trans('折', '折')}`
        } else if (item.type == 44) {
          params.showText =
            `${this.$trans('全场满', '全场满')} ` +
            item.conditionAmount +
            ` ${this.$trans('元减', '元减')} ` +
            item.value +
            ` ${this.$trans('元', '元')}`
        } else if (item.type == 55) {
          params.showText =
            `${this.$trans('部分商品打', '部分商品打')} ` +
            item.value.toString().split('.')[1] +
            ` ${this.$trans('折', '折')}`
        } else if (item.type == 66) {
          params.showText =
            `${this.$trans('部分商品减', '部分商品减')} ` +
            item.value +
            ` ${this.$trans('元', '元')}`
        } else if (item.type == 77) {
          params.showText =
            `${this.$trans('部分商品满', '部分商品满')} ` +
            item.conditionAmount +
            ` ${this.$trans('元打', '元打')} ` +
            item.value.toString().split('.')[1] +
            ` ${this.$trans('折', '折')}`
        } else if (item.type == 88) {
          params.showText =
            `${this.$trans('部分商品满', '部分商品满')} ` +
            item.conditionAmount +
            ` ${this.$trans('元减', '元减')} ` +
            item.value +
            ` ${this.$trans('元', '元')}`
        }
        return params
      })
      return a
    },
    // 优惠券选择列表
    couponVoSelectList: {
      get() {
        return JSON.parse(JSON.stringify(this.couponVoPayList))
      },
      set(newVal) {
        return newVal
      },
    },
    // 使用优惠券的商品
    useDiscountShop() {
      let goodsList = JSON.parse(JSON.stringify(this.goodsDetailVoList))
      if (JSON.stringify(this.discountObj) == '{}') {
        return []
      } else if (
        this.discountObj.type == 11 ||
        this.discountObj.type == 22 ||
        this.discountObj.type == 33 ||
        this.discountObj.type == 44
      ) {
        let a = []
        goodsList.forEach((item) => {
          a.push(item.skuId)
        })
        return a
      } else if (
        this.discountObj.type == 55 ||
        this.discountObj.type == 66 ||
        this.discountObj.type == 77 ||
        this.discountObj.type == 88
      ) {
        let a = goodsList.filter((item) => {
          return this.discountObj.goodsIdList.includes(item.skuId)
        })
        let d = a.reduce((b, c) => (b.price > c.price ? b : c))
        return [d.skuId]
      }
      return []
    },
  },
}
</script>
<style scoped>
.address-box {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.order-address-select {
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 30px;
}
.order-address-select .icon-dizhi {
  font-size: 40px;
  margin-right: 10px;
}
.order-info-container {
  padding: 30px;
  border: 1px solid #dfdfdf;
}
.no-payment-subtotal {
  font-size: 16px;
  margin-bottom: 24px;
  padding-top: 20px;
}
.no-payment-order {
  color: #666666;
  margin-bottom: 50px;
  font-size: 12px;
}
.no-payment-serial {
  cursor: pointer;
}
.no-payment-serial i {
  margin-left: 10px;
  font-size: 14px;
}
.btn-fill {
  cursor: pointer;
  width: 300px;
  margin: 80px auto 0;
}
.shop-detail-mask {
  position: fixed;
  /* left: 500px; */
  width: 75%;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.mask-content {
  background: #ffffff;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 700px;
  padding: 42px;
}
.mask-delete {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.mask-content-header {
  font-size: 24px;
  text-align: center;
  margin-bottom: 40px;
}
.mask-category__wrap {
  height: calc(700px - 100px - 123px - 90px);
  overflow-y: scroll;
  font-size: 24px;
}
.confirm-button {
  margin: 50px auto;
  width: 350px;
}
.mask-list-item {
  font-family: 'myCoolFontMedium';
  font-size: 24px;
  line-height: 75px;
}
.discount-item {
  background: url('../../assets/img/icon/back01.png');
  margin: 12px 12px 0 60px;
  height: 110px;
  width: 400px;
  cursor: pointer;
}
.discount-img-wrap {
  height: 110px;
  width: 110px;
  flex-shrink: 0;
}
.discount-content-wrap {
  padding: 30px 25px 15px 10px;
  height: 100%;
  flex-grow: 1;
}
.discount-content-top {
  align-items: flex-end;
}
.discount-content-title {
  font-size: 12px;
  margin-bottom: 8px;
}
.discount-content-right {
  align-items: flex-end;
  color: #30a0cd;
}
.discount-content-int {
  font-size: 24px;
}
.discount-content-tip {
  font-size: 15px;
}
.discount-content-hr {
  margin-top: 15px;
  width: 100%;
  height: 1px;
  background: #dddddd;
  margin-bottom: 10px;
}
.immediate-use {
  font-size: 12px;
}
.mask-payment-item {
  margin-bottom: 50px;
  cursor: pointer;
}
.mask-payment-item .icon {
  width: 38px;
  height: 38px;
  margin-right: 38px;
}
.mask__price {
  color: #2a9dcc;
}
</style>
<style>
.el-dropdown {
  font-size: 12px;
}
.el-dropdown-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.el-dropdown-link .iconfont {
  font-size: 14px;
  line-height: 14px;
  margin-left: 10px;
}
.el-dropdown-menu {
  border: none;
}
</style>
